import React from 'react'
import writtenNumber from 'number-in-letters'
import {capitalize} from '../../../helpers/string-format'
import {currencyName, getFormatCurrency, getFormatNumber} from '../../../helpers/format-number'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import {shortFormatDate} from '../../../helpers/dates'
import {groupByConceptCode} from '../../../helpers/payslip'
import PayslipReportHeader from '../../Molecules/PayslipReportHeader'
import PayslipContractItem from '../../Atoms/Items/PayslipContractItem'
import PayslipContractInformationSection from '../Sections/PayslipContractInformationSection'
import {mediaFromM} from '../../../helpers/media-queries'
import {useMedia} from '../../../hooks/useMedia'
import {isEqual} from '../../../helpers/utils'

const PayslipSettlement = props => {
  const fromM = useMedia(mediaFromM)
  const itemsEarnings = groupByConceptCode(props?.Data?.concepts['INGRESO'])
  const itemsDeductions = groupByConceptCode(props?.Data?.concepts['DESCUENTO'])

  return (
    <div>
      <PayslipReportHeader
        picture={props?.Employer?.picture}
        businessName={props?.Employer?.business_name}
        nit={props?.Employer?.nit}
        dv={props?.Employer?.dv}
        title="Liquidación definitiva de contrato de trabajo"
      />

      <PayslipContractItem
        employeeName={`${props?.Data?.first_name} ${props?.Data?.middle_name} ${props?.Data?.last_name} ${props?.Data?.surname}`}
        identificationType={props?.Data?.identification_type}
        identificationNumber={props?.Data?.identification}
        contractHash={props?.Data?.contract_hash}
      />

      <PayslipContractInformationSection netPay={props?.Data?.total?.net_pay} employerSession={props?.employerSession}>
        <p className="smaller s-mb-0">{`Contrato ${props?.Data?.contract_type.toLowerCase()}`}</p>
        <p className="smaller s-mb-0">{`Tiempo Trabajado: (${shortFormatDate(
          props?.Data?.hire_date
        )} - ${shortFormatDate(props?.Data?.termination_date)}) ${props?.Data?.days_old} días`}</p>
        <p className="smaller s-mb-0">{`Salario: ${getFormatCurrency(
          props?.Data?.salary,
          props?.employerSession?.code_iso_3,
          !props?.employerSession?.not_include_decimals_in_calculation
        )}`}</p>
        <p className="smaller s-mb-0">{`Cargo: ${capitalize(props?.Data?.job)} ${
          props?.Data?.area && `| ${props?.Data?.area}`
        }`}</p>
        {props?.Data?.account_type && (
          <p className="smaller s-mb-0">{`${capitalize(props?.Data?.account_type)} ${
            props?.Data?.account_number
          } ${capitalize(props?.Data?.bank)}`}</p>
        )}
        <p className="smaller s-mb-0">{capitalize(props?.Data?.termination_reason)}</p>
      </PayslipContractInformationSection>

      <Table
        heads={fromM ? ['', 'Ingresos', 'Descuentos'] : []}
        className="s-right s-mb-3"
        classNameTh="s-px-0 s-py-4px smaller s-color-title border-bottom"
      >
        {Array.isArray(itemsEarnings) &&
          itemsEarnings.map(earning => {
            if (isEqual(earning.amount, 0)) return null

            return (
              <TableRow key={earning.id} classNameTd="s-px-0 s-py-4px s-color-text smaller border-bottom">
                <div className="s-left">
                  <p>{`${earning.code} - ${earning.description}`}</p>
                  <p className="smaller s-color-light-text">
                    Cant: {getFormatNumber(earning.quantity)}{' '}
                    {earning.base &&
                      `| ${shortFormatDate(earning.begins_at)} - ${shortFormatDate(
                        earning.ends_at
                      )} | Base: ${getFormatCurrency(
                        earning.base,
                        props?.employerSession?.code_iso_3,
                        !props?.employerSession?.not_include_decimals_in_calculation
                      )}`}
                  </p>
                </div>
                <p>
                  {getFormatCurrency(
                    earning.amount,
                    props?.employerSession?.code_iso_3,
                    !props?.employerSession?.not_include_decimals_in_calculation
                  )}
                </p>
                <p></p>
              </TableRow>
            )
          })}

        {Array.isArray(itemsDeductions) &&
          itemsDeductions.map(deduction => {
            if (isEqual(deduction.amount, 0)) return null

            return (
              <TableRow key={deduction.id} classNameTd="s-px-0 s-py-4px s-color-text smaller border-bottom">
                <div className="s-left">
                  <p>{`${deduction.code} - ${deduction.description}`}</p>
                  <p className="smaller s-color-light-text">Cant: {getFormatNumber(deduction.quantity)}</p>
                </div>
                <p></p>
                <p>
                  {getFormatCurrency(
                    deduction.amount,
                    props?.employerSession?.code_iso_3,
                    !props?.employerSession?.not_include_decimals_in_calculation
                  )}
                </p>
              </TableRow>
            )
          })}

        <TableRow classNameTd="s-px-0 s-py-4px s-color-title smaller">
          <p></p>
          <p>
            {getFormatCurrency(
              props?.Data?.total?.total_earnings,
              props?.employerSession?.code_iso_3,
              !props?.employerSession?.not_include_decimals_in_calculation
            )}
          </p>
          <p>
            {getFormatCurrency(
              props?.Data?.total?.total_deductions,
              props?.employerSession?.code_iso_3,
              !props?.employerSession?.not_include_decimals_in_calculation
            )}
          </p>
        </TableRow>
      </Table>
      <p className="smaller s-color-text s-mb-4">
        {`SON: ${writtenNumber(props?.Data?.total?.net_pay, {
          lang: 'es',
        }).toUpperCase()} ${currencyName[props?.employerSession?.code_iso_3]}`}
      </p>

      {fromM && (
        <>
          <section className="smallest s-color-light-text m-mb-128px">
            <p className="s-mb-1">
              ARTICULO 128 Ley 50/90 no constituyen salario las sumas ocasionales y por mera liberalidad que recibe el
              trabajador del empleador como primas, bonificaciones o gratificaciones ocasionales, participación de
              utilidades, excedentes de las empresas de economía solidaria y de lo que recibe no para su beneficio, ni
              para enriquecer su patrimonio, sino para desempeñar a cabalidad con sus funciones como gastos de
              representación, medios de transporte, elementos de trabajo y otros semejantes.
            </p>
            <p className="s-mb-1">CONSTANCIA</p>
            <ol>
              <li>
                Que el patrono ha incorporado en la liquidación anterior, en lo pertinente, la totalidad de los valores
                correspondientes a salarios, descansos remunerados, cesantías, vacaciones, primas y en general todo
                concepto relacionado con salarios, prestaciones o indemnizaciones.
              </li>
              <li>
                Que no obstante lo anterior, se hará constatar por las partes que con el pago de la suma de dineros a
                que hace referencia la presente liquidación, queda transada cualquier diferencia relativa al contrato de
                trabajo que ha quedado terminado, pues ha sido su común mínimo transar definitivamente, como en efecto
                transa, todo reclamo pasado, presente o futuro que tenga por causa el mencionado contrato. Por
                consiguiente, esta transacción tiene como efecto la extinción de las obligaciones provenientes de la
                relación laboral que existe entre el patrono y el trabajador quienes recíprocamente se declaran a paz y
                salvo por los conceptos expresados.
              </li>
            </ol>
          </section>
          <section className="ed-grid s-grid-2 s-mb-0 smaller s-color-light-text">
            <div className="border-top">
              <p className="s-mb-0">
                {props?.Data?.first_name} {props?.Data?.middle_name} {props?.Data?.last_name} {props?.Data?.surname}
              </p>
              <p className="s-mb-0">
                {props?.Data?.identification_type} {props?.Data?.identification}
              </p>
            </div>
            <div className="border-top">
              <p className="s-mb-0">Empleador</p>
            </div>
          </section>
        </>
      )}
    </div>
  )
}

export default PayslipSettlement
