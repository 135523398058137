import React, {useState} from 'react'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatConceptList from '../FloatConceptList'
import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'

const SelectConceptInput = ({
  id = 'concept_id',
  name = 'concept',
  label = 'Concepto',
  placeholder = 'Escribe para buscar',
  initialValue = '',
  isRequired = false,
  isDisabled = false,
  concepts = [],
  onSelect = () => {},
  ...rest
}) => {
  const [value, setValue] = useState(initialValue)
  const [list, setList] = useState(concepts)
  const [listIndex, setListIndex] = useState({active: 0, max: len(concepts)})

  const handleFilter = searchValue => {
    const result = concepts.filter(concept => {
      return (
        concept.description.toLowerCase().includes(searchValue.toLowerCase()) ||
        concept.code.toLowerCase().includes(searchValue.toLowerCase())
      )
    })

    const size = isEmpty(result) ? 0 : len(result) - 1

    setList(result)
    setListIndex({active: 0, max: size})
  }

  const handleOnChangeInput = newValue => {
    setValue(newValue)

    // clear the concept because the user change the input value and not to selected a
    // new conceptID
    onSelect(0)

    handleFilter(newValue)
  }

  const handleKeyPress = e => {
    if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

    e.preventDefault()

    if (isEqual(e.key, 'Enter')) {
      handleOnSelect(null)
      return
    }

    moveIndexPosition(e.key)
  }

  const moveIndexPosition = move => {
    setListIndex(currentIndex => {
      if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
        return currentIndex
      }

      if (
        isEqual(currentIndex?.active, currentIndex?.max) &&
        isEqual(move, 'ArrowDown')
      ) {
        return currentIndex
      }

      const newActiveIndex = isEqual(move, 'ArrowDown')
        ? ++currentIndex.active
        : --currentIndex.active

      return {active: newActiveIndex, max: currentIndex?.max}
    })
  }

  const handleOnSelect = newIndex => {
    const ix = isNull(newIndex) ? listIndex.active : newIndex
    const conceptSelected = list[ix]

    setList([conceptSelected])
    setListIndex({active: 0, max: 0})
    setValue(conceptSelected?.description)
    onSelect(conceptSelected?.id)
  }

  return (
    <div>
      <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
        <FormInput
          type="search"
          isRequired={isRequired}
          isDisabled={isDisabled}
          label={label}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            handleOnChangeInput(e.target.value)
          }}
          autoComplete="off"
          onKeyDown={handleKeyPress}
          {...rest}
        />
        {!isDisabled && (
          <FloatConceptList
            concepts={list}
            onSelect={handleOnSelect}
            activeIndex={listIndex?.active}
          />
        )}
      </FloatContainer>
    </div>
  )
}

export default SelectConceptInput
