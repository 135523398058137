import React, {forwardRef} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const DeleteModal = forwardRef((props, ref) => {
  const {deleteFunction} = props

  const handleCloseModal = () => {
    ref.current.closeModal()
  }

  return (
    <ModalTemplate ref={ref} className="s-center">
      <h3>¿Quieres eliminar este elemento?</h3>
      <p className="s-mb-24px">Una vez que lo hagas, no hay forma de recuperarlo</p>
      <div className="buttons-container">
        <button className="button cancel" onClick={handleCloseModal}>
          No, cancelar
        </button>
        <button className="button delete" onClick={deleteFunction}>
          Sí, eliminar
        </button>
      </div>
    </ModalTemplate>
  )
})

export default DeleteModal
