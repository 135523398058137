import React, {useState,useContext, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Icon from '../../Atoms/Icon'
import {PayrollContext} from '../../../contexts/contexts'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import PayrollHeaderWrapper from '../../Wrappers/PayrollHeaderWrapper'
import { payrollDateRoute } from '../../../config/routes'
import {useMedia} from '../../../hooks/useMedia'
import {mediaToM} from '../../../helpers/media-queries'
import IconBackground from '../../Atoms/IconBackground'
import ConfirmModal from '../Modals/ConfirmModal'
import { generateElectronicPayrollDetailReport, generateElectronicPayrollDocumentsReport, prepareElectronicPayroll, sendElectronicPayroll } from '../../../services/electronicPayroll'
import { notifyError } from '../../../services/notification'
import PendingElectronicPayrollModal from '../Modals/PendingElectronicPayrollModal'
import { isEqual } from '../../../helpers/utils'
import useObjectURL from '../../../hooks/useObjectURL'
import SelectButton from '../../Molecules/Selects/SelectButton'

const CONFIRM_PREPARE = 'CONFIRM_PREPARE'
const CONFIRM_SEND = 'CONFIRM_SEND'

const ElectronicPayrollHeader = ({summary = null, onUpdateSummary= ()=>{}, onUpdateSummaryPromise = ()=>{}}) => {
  const history = useHistory()
  const {month} = useParams()
  const toM = useMedia(mediaToM)

  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const {payrollDate} = useContext(PayrollContext)
  const confirmModal = useRef()
  const pendingElectronicPayroll = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [confirmContent, setConfirmContent] = useState(null)
  
  const {setObject, objectURL} = useObjectURL(null)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const downloadLinkReport = useRef()

  const openConfirmModal = action => {
    setConfirmContent(CONFIRMATIONS[action])
    confirmModal.current.openModal()
  }

  const handlePrepare = () => {
    confirmModal.current.closeModal()

    const payload = {year: payrollDate?.year, month: payrollDate?.month}
    
    setIsLoading(true)
    
    prepareElectronicPayroll(payload)
    .then(() => onUpdateSummaryPromise())
    .then(summary => console.info("PREPARE:", summary))
    .catch(error => dispatch(notifyError(error)))
    .finally(() => setIsLoading(false))
  }

  const handleSend = () => {
    confirmModal.current.closeModal()

    const payload = {year: payrollDate?.year, month: payrollDate?.month}
    setIsLoading(true)

    sendElectronicPayroll(payload)
    .then((response) =>{
      if (isEqual(response, "PENDING")) {
        pendingElectronicPayroll.current.openModal()
      }

       return onUpdateSummaryPromise()
    })
    .then(summary => console.info("SEND:", summary))
    .catch(error => dispatch(notifyError(error)))
    .finally(() => setIsLoading(false))
  }

  const handleOnClickGenerateDetailReport = () => {
    setIsLoadingDownloadReport(true)

    generateElectronicPayrollDetailReport(payrollDate?.year, payrollDate?.month)
      .then(({data, headers}) => downloadReport(data, headers))
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const handleOnClickGenerateDocumentsReport = () => {
    setIsLoadingDownloadReport(true)

    generateElectronicPayrollDocumentsReport(payrollDate?.year, payrollDate?.month)
      .then(({data, headers}) => downloadReport(data, headers))
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const downloadReport = (file, headers) => {
    setObject(file)
    setFileNameDownloaded(headers['x-file-name'])

    downloadLinkReport.current.click()

    // after of clicked then reset the state
    setObject(null)
    setFileNameDownloaded('')
  }

  const CONFIRMATIONS = {
    [CONFIRM_PREPARE]: {
      title: 'Prepara tus documentos',
      content: '¿Estás seguro de continuar?, esta acción eliminará los borradores y creará nuevos documentos',
      hasCodeConfirmation: false,
      fn: handlePrepare,
    },
    [CONFIRM_SEND]: {
      title: 'Envia tus documentos',
      content:
        '¿Estás seguro de continuar?, esta acción no se puede revertir, enviará todos los borradores a la DIAN para su aceptación.',
      hasCodeConfirmation: true,
      fn: handleSend,
    },
  }

  return (
    <PayrollHeaderWrapper>
      <div className="s-cross-center s-flex-gap-8px m-flex-gap-16px overflow-ellipsis nowrap">
        <Icon
          className="cursor-pointer"
          svg="arrow-uturn-left"
          size={toM ? '16' : '24'}
          onClick={() => history.push(payrollDateRoute(payrollDate))}
        />
        <div className="overflow-ellipsis">
          <h1 className="t2 s-mb-1 overflow-ellipsis">Nómina Electrónica</h1>

          <div className="flex s-flex-gap-8px m-flex-gap-12px">
            <IconBackground
              title="Nómina electrónica"
              svg="rectangle-group"
              bgColor="s-bg-alt-yellow"
              color="black"
            />
            <span className="small code-font s-color-light-text">{month}</span>
          </div>
        </div>
      </div>
      <div className="s-cross-center s-flex-gap-8px m-flex-gap-12px">        
        <button
          type="button"
          className={`button ${toM && 'small'}`}
          onClick={() => openConfirmModal(CONFIRM_PREPARE)}
          disabled={!employerSession?.electronic_payroll_metadata?.is_enabled || isLoading}
        >
          <div className="s-cross-center s-main-center">
            <Icon svg="bolt" className="s-mr-4px" />
            <span>Prepara</span>
          </div>
        </button>
        
        { summary && 
          <>
            <button
              type="button"
              className={`button ghost ${toM && 'small'}`}
              onClick={() => openConfirmModal(CONFIRM_SEND)}
              disabled={!employerSession?.electronic_payroll_metadata?.is_enabled || !(summary?.DRAFT > 0) || isLoading}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="rocket" className="s-mr-4px" />
                <span>Envia</span>
              </div>
            </button>

            <SelectButton
              isDisable={isLoadingDownloadReport}
              classButton={`ghost ${toM && 'small'}`}
              iconName="document"
              description={isLoadingDownloadReport ? 'Descargando...' : 'Reportes'}
              options={[
                {
                  key: 1,
                  description: 'Lista de documentos',
                  icon: 'excel',
                  onClick: handleOnClickGenerateDocumentsReport,
                  isVisible: true,
                },
                {
                  key: 2,
                  description: 'Detalle',
                  icon: 'excel',
                  onClick: handleOnClickGenerateDetailReport,
                  isVisible: true,
                }
              ]}
            />

            <button
              type="button"
              className={`button ghost ${toM && 'small'}`}
              onClick={onUpdateSummary}
              disabled={!employerSession?.electronic_payroll_metadata?.is_enabled || isLoading}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="refresh" size={toM ? '20px' : '24px'} />
              </div>
            </button>
          </>
        }
      </div>

      <ConfirmModal
        ref={confirmModal}
        hasCodeConfirmation={confirmContent?.hasCodeConfirmation}
        confirmFunction={confirmContent?.fn}
        title={confirmContent?.title}
        content={confirmContent?.content}
      />

      <PendingElectronicPayrollModal ref={pendingElectronicPayroll}/>
      
      {/* This link is hidden, we only use for download the payroll report */}
      <a
        ref={downloadLinkReport}
        href={objectURL}
        download={fileNameDownloaded}
        className="hidden"
      >
        file downloaded
      </a>
    </PayrollHeaderWrapper>
  )
}

export default ElectronicPayrollHeader
