import React, {useState, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import ProcessAvatar from '../../Atoms/Templates/ProcessAvatar'
import Icon from '../../Atoms/Icon'
import ProcessStatusIcon from '../../Atoms/ProcessStatusIcon'
import DocumentTextButton from '../../Atoms/Buttons/DocumentTextButton'
import CloseTextButton from '../../Atoms/Buttons/CloseTextButton'
import EditTextButton from '../../Atoms/Buttons/EditTextButton'
import {isEqual} from '../../../helpers/utils'
import {
  ACTION_APPROVE,
  findProcessStage,
  PROCESS_EXEC_SETTLEMENT,
  STAGE_NOVELTIES,
  STAGE_PAYSHEET,
  STATUS_HISTORY,
  STATUS_PRE_PAYROLL,
} from '../../../helpers/payroll'
import TooltipContainer from '../../Organisms/Containers/TooltipContainer'
import Tooltip from '../../Atoms/Templates/Tooltip'
import useObjectURL from '../../../hooks/useObjectURL'
import {getByEmployerIDAndHash} from '../../../services/payrollReports'
import {notifyError} from '../../../services/notification'
import ConfirmModal from '../../Organisms/Modals/ConfirmModal'
import {execProcess} from '../../../services/process'
import routes from '../../../config/routes'
import EditProcessModal from '../../Organisms/Modals/EditProcessModal'

const ProcessItem = ({process, processType}) => {
  const {pathname} = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const stage = process?.is_paid_full ? STAGE_PAYSHEET : process.stage

  const {setObject, objectURL} = useObjectURL(null)
  const downloadLinkReport = useRef()
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)

  const confirmModalClose = useRef()
  const [isLoadingClose, setIsLoadingClose] = useState(false)

  const editModal = useRef()

  const goToProcess = () => {
    history.push({
      pathname: `${pathname}${routes.payroll.processes}/${process?.hash}`,
      hash: findProcessStage(stage)?.hash,
    })
  }

  const goToLastStage = () => {
    history.push({
      pathname: `${pathname}${routes.payroll.processes}/${process?.hash}`,
      hash: findProcessStage(
        Array.isArray(processType?.config?.rules?.stages)
          ? processType?.config?.rules?.stages[processType?.config?.rules?.stages.length - 1]
          : STAGE_PAYSHEET
      )?.hash,
    })
  }

  const handleOnClickPayrollSpreadsheet = () => {
    setIsLoadingDownloadReport(true)

    getByEmployerIDAndHash(
      process?.hash,
      response => {
        setObject(new Blob([response]))

        downloadLinkReport.current.click()
        setObject(null)
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleOpenConfirmCloseProcessModal = () => {
    confirmModalClose.current.openModal()
  }

  const handleOpenEditProcessModal = () => {
    editModal.current.openModal()
  }

  const handleOnCloseProcess = () => {
    confirmModalClose.current.closeModal()
    setIsLoadingClose(true)

    const payload = {
      process_type: process?.process_type_code,
      action: ACTION_APPROVE,
      hash: process?.hash,
    }

    execProcess(
      payload,
      response => {
        setIsLoadingClose(false)
        goToLastStage()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingClose(false)
      }
    )
  }

  return (
    <article className="s-mb-2 normal-radius s-bg-body s-px-2 s-pt-2 s-pb-0">
      <header className="s-cross-center nowrap s-mb-4px cursor-pointer" onClick={goToProcess}>
        <ProcessAvatar className="s-mr-1" icon={processType?.config?.icon} color={processType?.config?.color} />
        <div className="overflow-ellipsis">
          <p className="s-mb-0 small overflow-ellipsis">{process?.description}</p>
          <p className="s-mb-0 s-color-light-text smaller code-font">
            <span>{process?.hash}</span>
          </p>
        </div>
      </header>

      <div className="s-main-justify smaller border-top s-py-1">
        <div className="s-cross-center">
          <ProcessStatusIcon status={process?.status} className="s-mr-4px" />
          {isEqual(process?.status, STATUS_HISTORY) && !process?.is_paid_full && (
            <TooltipContainer className="s-main-center">
              <Icon svg="exclamation" color="#D0981B" size="16" />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>Tienes empleados sin pagar</span>
                </div>
              </Tooltip>
            </TooltipContainer>
          )}
        </div>
        <div className="s-cross-center">
          {!isEqual(process?.stage, STAGE_NOVELTIES) && (
            <DocumentTextButton
              text="Planilla"
              color="text"
              className="s-mr-4px"
              onClick={handleOnClickPayrollSpreadsheet}
              isDisable={isLoadingDownloadReport}
            />
          )}

          {/* Currently only support Settlement edition */}
          {isEqual(process?.status, STATUS_PRE_PAYROLL) &&
            isEqual(processType?.config?.executor, PROCESS_EXEC_SETTLEMENT) && (
              <EditTextButton text="Edita" color="text" onClick={handleOpenEditProcessModal} />
            )}

          {isEqual(process?.stage, STAGE_PAYSHEET) && isEqual(process?.status, STATUS_PRE_PAYROLL) && (
            <CloseTextButton
              text={isLoadingClose ? 'Cerrando...' : 'Cierra'}
              color="text"
              onClick={handleOpenConfirmCloseProcessModal}
              isDisable={isLoadingClose}
            />
          )}
        </div>
      </div>

      {/* This link is hidden, we only use for download the payroll report */}
      <a
        href={objectURL}
        download={`Planilla_${process?.description}.xlsx`}
        ref={downloadLinkReport}
        className="hidden"
      >
        Planilla
      </a>

      {/* Modal para editar un proceso */}
      <EditProcessModal ref={editModal} process={process} config={processType?.config} />

      {/* Modal para cerrar un proceso */}
      <ConfirmModal
        ref={confirmModalClose}
        confirmFunction={handleOnCloseProcess}
        hasCodeConfirmation
        title="Cerrar proceso"
        content={
          <>
            ¿Estás seguro de cerrar el proceso <span className="s-color-title">{process?.description}</span>? Esta
            acción no se puede revetir.
          </>
        }
      />
    </article>
  )
}

ProcessItem.propTypes = {}

export default ProcessItem
