import React, {useState, forwardRef, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import LastProcessEmptyState from '../Emptys/LastProcessEmptyState'
import {generateAccountingInterface, getAccountingInterfacesByHashProcess} from '../../../services/accountingInterface'
import {notifyError} from '../../../services/notification'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import Icon from '../../Atoms/Icon'
import {generateAccountingInterfaceFile} from '../../../services/accountingInterfaceFile'
import useObjectURL from 'hooks/useObjectURL'

const AccountingInterfaceModal = forwardRef(
  (
    {process, accountingInterfaceConfig, accountingInterfaceConfigObj, accountingInterfaces, onFinish = () => {}},
    ref
  ) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [loadingId, setLoadingId] = useState(0)
    const downloadLinkReport = useRef()
    const {setObject, objectURL} = useObjectURL(null)
    const [fileNameDownloaded, setFileNameDownloaded] = useState('')

    const handleGenerate = () => {
      if (!Array.isArray(accountingInterfaceConfig) || isEmpty(accountingInterfaceConfig)) return

      setIsLoading(true)
      const requests = []

      // add the request to the array for every configuration
      accountingInterfaceConfig.forEach(accountingInterfaceConfig => {
        const payload = {
          accounting_interface_configuration_id: accountingInterfaceConfig.id,
          process_hashes: [process?.hash],
          year: process?.year,
          month: process?.month,
        }

        requests.push(generateAccountingInterface(payload))
      })

      // generate the interface of the process for each configuration
      Promise.all(requests)
        .then(() => {
          // when all the interfaces are generated, get the interfaces of the process
          return getAccountingInterfacesByHashProcess(process?.hash)
        })
        .then(response => onFinish(response)) // call onFinish with the interfaces
        .catch(error => dispatch(notifyError(error)))
        .finally(() => setIsLoading(false))
    }

    const handleDownloadFile = (recordId, configId) => {
      if (isLoading) return

      setIsLoading(true)
      setLoadingId(recordId)
      const payload = {
        account_interface_configuration_id: configId,
        process_hashs: [process?.hash],
        year: process?.year,
        month: process?.month,
      }

      generateAccountingInterfaceFile(
        payload,
        (response, headers) => {
          setObject(response)
          setFileNameDownloaded(headers['x-file-name'])

          downloadLinkReport.current.click()

          // after of clicked then reset the state
          setObject(null)
          setFileNameDownloaded('')
          setIsLoading(false)
          setLoadingId(0)
        },
        error => {
          dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
          setIsLoading(false)
          setLoadingId(0)
        }
      )
    }

    if (isEmpty(accountingInterfaceConfig)) {
      return
    }

    return (
      <ModalTemplate ref={ref} className="s-center medium">
        <h3>Interfaz Contable</h3>

        {(isNull(accountingInterfaces) || isEmpty(accountingInterfaces)) && (
          <LastProcessEmptyState
            title="Vaya, aún no has generado la interfaz contable"
            subtitle="¿Qué tal si lo haces ahora?"
            captionActionButton="Generar"
            onClickActionButton={handleGenerate}
            buttonSize="normal"
            isDisableActionButton={isLoading}
          />
        )}

        {!isEmpty(accountingInterfaces) && Array.isArray(accountingInterfaces) && (
          <Table heads={['Nombre', 'Acciones']} classNameTh="m-center" className="table-template whitespace-nowrap">
            {accountingInterfaces.map((record, ix) => (
              <TableRow key={record.id}>
                <p className="m-center">
                  {accountingInterfaceConfigObj[record?.accounting_interface_configuration_id].description}
                </p>
                <p className="s-main-center s-cross-center">
                  <Icon
                    svg="download"
                    className={`s-mr-1 s-color-blue blue ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={() => handleDownloadFile(record.id, record?.accounting_interface_configuration_id)}
                  />
                  {isEqual(loadingId, record.id) && isLoading && <span className="loading blue small s-mr-1"></span>}
                </p>
              </TableRow>
            ))}
          </Table>
        )}

        {/* This link is hidden, we only use for download the files */}
        <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
          file downloaded
        </a>
      </ModalTemplate>
    )
  }
)

export default AccountingInterfaceModal
