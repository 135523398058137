import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import Icon from '../../Atoms/Icon'
import Logo from '../../Atoms/Logo'
import HeaderUserAvatar from '../../Atoms/Avatars/HeaderUserAvatar'
import NotificationButton from '../../Atoms/Buttons/NotificationButton'
import {MainSidebarContext} from '../../../contexts/contexts'
import FloatContainer from '../../Wrappers/FloatContainer'
import NotificationsPanel from '../NotificationsPanel'
import FlagIcon from '../../Atoms/Flag'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'

const MainHeader = () => {
  const {collapsed, setCollapsed} = useContext(MainSidebarContext)
  const employerSession = useSelector(employerSelectors.getSession)

  return (
    <header className="main-header">
      <div className="s-100 s-main-justify s-cross-center s-px-16px lg-px-24px">
        <Icon onClick={() => setCollapsed(!collapsed)} className="header-button" svg="bars" />

        <Logo size={{height: 's-h-24px'}} />

        <div className="s-cross-center">
          <FlagIcon countryCode={employerSession?.code_iso_3} className="flex s-mr-12px lg-mr-16px" />
          <div className="s-relative s-mr-12px lg-mr-16px">
            <FloatContainer>
              <NotificationButton />
              <NotificationsPanel />
            </FloatContainer>
          </div>
          <HeaderUserAvatar />
        </div>
      </div>
    </header>
  )
}

export default MainHeader
