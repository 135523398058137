import React from 'react'
import {shortFormatDate} from '../../../helpers/dates'
import {getFormatCurrency, getFormatNumber} from '../../../helpers/format-number'
import {mediaFromM} from '../../../helpers/media-queries'
import {groupByConceptCode} from '../../../helpers/payslip'
import {capitalize} from '../../../helpers/string-format'
import {useMedia} from '../../../hooks/useMedia'
import PayslipContractItem from '../../Atoms/Items/PayslipContractItem'
import PayslipReportHeader from '../../Molecules/PayslipReportHeader'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import PayslipContractInformationSection from '../Sections/PayslipContractInformationSection'
import {isEqual} from '../../../helpers/utils'

const PayslipPayroll = props => {
  const fromM = useMedia(mediaFromM)
  const itemsEarnings = groupByConceptCode(props?.Data?.concepts['INGRESO'])
  const itemsDeductions = groupByConceptCode(props?.Data?.concepts['DESCUENTO'])

  return (
    <div>
      <PayslipReportHeader
        picture={props?.Employer?.picture}
        businessName={props?.Employer?.business_name}
        nit={props?.Employer?.nit}
        dv={props?.Employer?.dv}
        title={`Comprobante de Pago del ${shortFormatDate(props?.Process?.begins_at)} al ${shortFormatDate(
          props?.Process?.ends_at
        )}`}
      />

      <PayslipContractItem
        employeeName={`${props?.Data?.first_name} ${props?.Data?.middle_name} ${props?.Data?.last_name} ${props?.Data?.surname}`}
        identificationType={props?.Data?.identification_type}
        identificationNumber={props?.Data?.identification}
        contractHash={props?.Data?.contract_hash}
      />

      <PayslipContractInformationSection netPay={props?.Data?.total?.net_pay} employerSession={props?.employerSession}>
        <p className="smaller s-mb-0">{`Contrato ${props?.Data?.contract_type.toLowerCase()}`}</p>
        <p className="smaller s-mb-0">{`Fecha de Ingreso: ${shortFormatDate(props?.Data?.hire_date)}`}</p>
        <p className="smaller s-mb-0">{`Salario: ${getFormatCurrency(
          props?.Data?.salary,
          props?.employerSession?.code_iso_3,
          !props?.employerSession?.not_include_decimals_in_calculation
        )}`}</p>
        <p className="smaller s-mb-0">{`Cargo: ${capitalize(props?.Data?.job)} ${
          props?.Data?.area && `| ${props?.Data?.area}`
        }`}</p>
        {props?.Data?.account_type && (
          <p className="smaller s-mb-0">{`${capitalize(props?.Data?.account_type)} ${
            props?.Data?.account_number
          } ${capitalize(props?.Data?.bank)}`}</p>
        )}
        <p className="smaller s-mb-0">{getEntities(props?.Data?.employee_entity_histories)}</p>
      </PayslipContractInformationSection>

      <section className="m-main-justify m-cross-baseline">
        <Table
          heads={
            fromM
              ? [
                  <p className="s-left">Ingresos</p>,
                  `${getFormatCurrency(
                    props?.Data?.total?.total_earnings,
                    props?.employerSession?.code_iso_3,
                    !props?.employerSession?.not_include_decimals_in_calculation
                  )}`,
                ]
              : ['Concepto', 'Total']
          }
          className={`${fromM && 'm-48'} s-right`}
          classNameTh="s-px-0 s-py-4px small s-color-title border-bottom whitespace-nowrap"
        >
          {Array.isArray(itemsEarnings) &&
            itemsEarnings.map(earning => {
              if (isEqual(earning.amount, 0)) return null

              return (
                <TableRow key={earning.id} classNameTd="s-px-0 s-py-4px s-color-text smaller border-bottom">
                  <div className="s-left">
                    <p>{`${earning.code} - ${earning.description}`}</p>
                    <p className="smaller s-color-light-text">
                      Cant: {getFormatNumber(earning.quantity)}{' '}
                      {earning.base &&
                        `| ${shortFormatDate(earning.begins_at)} - ${shortFormatDate(
                          earning.ends_at
                        )} | Base: ${getFormatCurrency(
                          earning.base,
                          props?.employerSession?.code_iso_3,
                          !props?.employerSession?.not_include_decimals_in_calculation
                        )}`}
                    </p>
                  </div>
                  <p className="whitespace-nowrap">
                    {getFormatCurrency(
                      earning.amount,
                      props?.employerSession?.code_iso_3,
                      !props?.employerSession?.not_include_decimals_in_calculation
                    )}
                  </p>
                </TableRow>
              )
            })}
        </Table>

        <Table
          heads={
            fromM
              ? [
                  <p className="s-left">Descuentos</p>,
                  `${getFormatCurrency(
                    props?.Data?.total?.total_deductions,
                    props?.employerSession?.code_iso_3,
                    !props?.employerSession?.not_include_decimals_in_calculation
                  )}`,
                ]
              : ['Concepto', 'Total']
          }
          className={`${fromM && 'm-48'} s-right`}
          classNameTh="s-px-0 s-py-4px small s-color-title border-bottom whitespace-nowrap"
        >
          {Array.isArray(itemsDeductions) &&
            itemsDeductions.map(deduction => {
              if (isEqual(deduction.amount, 0)) return null

              return (
                <TableRow key={deduction.id} classNameTd="s-px-0 s-py-4px s-color-text smaller border-bottom">
                  <div className="s-left">
                    <p>{`${deduction.code} - ${deduction.description}`}</p>
                    <p className="smaller s-color-light-text">Cant: {getFormatNumber(deduction.quantity)}</p>
                  </div>
                  <p className="whitespace-nowrap">
                    {getFormatCurrency(
                      deduction.amount,
                      props?.employerSession?.code_iso_3,
                      !props?.employerSession?.not_include_decimals_in_calculation
                    )}
                  </p>
                </TableRow>
              )
            })}
        </Table>
      </section>

      {/* Bank Commission */}
      {props?.Data?.total?.bank_commission > 0 && (
        <section className="m-main-justify m-cross-baseline">
          <Table
            heads={
              fromM
                ? [
                    <>
                      <p className="s-left">Comisión Bancaría</p>
                      <p className="s-left smaller s-color-light-text">{props?.Data?.total?.formula_commission_bank}</p>
                    </>,
                    `${getFormatCurrency(
                      props?.Data?.total?.bank_commission,
                      props?.employerSession?.code_iso_3,
                      true
                    )}`,
                  ]
                : ['Cómisión Bancaría', 'Total']
            }
            className={`${fromM && 'm-48'} s-right`}
            classNameTh="s-px-0 s-py-4px small s-color-title border-bottom whitespace-nowrap"
          >
            <TableRow classNameTd={`${fromM ? 'hidden' : ''} s-px-0 s-py-4px s-color-text smaller border-bottom`}>
              <div></div>
              <div>
                {getFormatCurrency(props?.Data?.total?.bank_commission, props?.employerSession?.code_iso_3, true)}
              </div>
            </TableRow>
          </Table>
        </section>
      )}
    </div>
  )
}

function getEntities(entities) {
  if (!Array.isArray(entities)) return ''

  return entities
    .map(entity => {
      return `${entity.entity_type_description}: ${entity.business_name}`
    })
    .join(' | ')
}

export default PayslipPayroll
