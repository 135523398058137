import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/processes'

export const execProcess = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(RESOURCE, payload, callback, callbackErr)
}

export const createProcess = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, callback, callbackErr)
}

export const editProcess = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.put(`${RESOURCE}/edit`, payload, resolve, reject)
  })
}
