import {decode} from 'jsonwebtoken'

const TOKEN_EMPLOYER_KEY = process.env.REACT_APP_TOKEN_EMPLOYER_KEY

export const getEmployerTokenData = () =>
  decode(sessionStorage.getItem(TOKEN_EMPLOYER_KEY))

export const calculateDV = nit => {
  const serie = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]
  const len = nit.length
  let dv = 0

  for (let i = 0; i < len; i++) {
    let seriePosition = len - i - 1
    dv += nit.substr(i, 1) * serie[seriePosition]
  }

  const module11 = dv % 11
  if (module11 > 1) {
    return 11 - module11
  }
  return module11
}
