import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/temporary-reports'

export const generateMonthlyTaxReport = (year, month)  => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/monthly-taxes?year=${year}&month=${month}`, 
      (data, headers) => resolve({data, headers}),
      reject
    )
  })
}

export const generateTaxDetail = (contractID, processHash)  => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/tax-attachments?contract-id=${contractID}&process-hash=${processHash}`, 
      (data, headers) => resolve({data, headers}),
      reject
    )
  })
}
