import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/electronic-payrolls'

export const getElectronicPayrollSummary = (year, month) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/summary?year=${year}&month=${month}`, resolve, reject)
  })
}

export const getElectronicPayrollEmployeeItems = (queryParams, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/employees?${queryParams}`,
    callback,
    callbackErr
  )
}

export const getElectronicPayrollDocuments = (hash, year, month) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/contracts/${hash}?year=${year}&month=${month}`, resolve, reject)
  })
}

export const prepareElectronicPayroll = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.post(`${RESOURCE}/prepare`, payload, resolve, reject)
  })
}

export const sendElectronicPayroll = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.put(`${RESOURCE}/send`, payload, resolve, reject)
  })
}

export const resendFailedElectronicPayroll = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.put(`${RESOURCE}/failed/resend`, payload, resolve, reject)
  })
}

export const regenerateDIANRejectedElectronicPayroll = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.post(`${RESOURCE}/dian-rejected/regenerate`, payload, resolve, reject)
  })
}

export const getXMLElectronicPayroll = id => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/${id}/files/xml`, resolve, reject)
  })
}

export const generateElectronicPayrollDetailReport = (year, month, contractsIDs)  => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/reports/details?year=${year}&month=${month}&contract_ids=${contractsIDs}`, 
      (data, headers) => resolve({data, headers}),
      reject
    )
  })
}

export const generateElectronicPayrollDocumentsReport = (year, month, contractsIDs)  => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/reports/documents?year=${year}&month=${month}&contract_ids=${contractsIDs}`, 
      (data, headers) => resolve({data, headers}), 
      reject
    )
  })
}
