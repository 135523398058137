export const groupByConceptCode = payslipItems => {
  if (!Array.isArray(payslipItems)) return null

  let mapGroup = new Map()

  payslipItems.forEach(item => {
    if (mapGroup.has(item?.code)) {
      let current = mapGroup.get(item?.code)

      current.amount += item.amount
      current.quantity += item.quantity || 0

      mapGroup.set(item.code, current)

      return
    }

    let conceptGrouped = {
      id: item.id,
      contract_id: item.contract_id,
      code: item.code,
      description: item.description,
      quantity: item.quantity || 0,
      amount: item.amount,
      base: item.base,
      concept_type: item.concept_type,
      begins_at: item.begins_at,
      ends_at: item.ends_at,
    }

    if (item.base) {
      conceptGrouped.begins_at = item.begins_at
      conceptGrouped.ends_at = item.ends_at
    }

    mapGroup.set(item.code, conceptGrouped)
  })

  let payslipItemGrouped = []

  for (const value of mapGroup.values()) {
    value.amount = Number(value?.amount?.toFixed(2))
    value.quantity = Number(value?.quantity?.toFixed(2))

    payslipItemGrouped.push(value)
  }

  return payslipItemGrouped
}
