import React, {useState, useRef, useContext, useEffect} from 'react'
import Tag from '../../Atoms/Tag'
import Icon from '../../Atoms/Icon'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import PayrollHeaderWrapper from '../../Wrappers/PayrollHeaderWrapper'
import {PayrollContext} from '../../../contexts/contexts'
import {isEmpty, isEqual, isNull, isUndefined, len} from '../../../helpers/utils'
import {
  findProcessStage,
  ACTION_APPROVE,
  ACTION_RUN,
  EXEC_STATUS_COMPLETED,
  EXEC_STATUS_PENDING,
  STAGE_NOVELTIES,
  STAGE_PAYSHEET,
  STATUS_FAILURE,
  STATUS_HISTORY,
  STATUS_PRE_PAYROLL,
  STAGE_PAYMENTS,
} from '../../../helpers/payroll'
import ProcessStatusIcon from '../../Atoms/ProcessStatusIcon'
import {
  getByEmployerIDAndHash,
  getConceptSummaryByEmployerIDAndHash,
  getHistoryPayrollByRange,
  getPrimaReportByEmployerIDAndHash,
} from '../../../services/payrollReports.js'
import {useMedia} from '../../../hooks/useMedia'
import {mediaToM} from '../../../helpers/media-queries'
import ConfirmModal from '../Modals/ConfirmModal'
import {execProcess} from '../../../services/process'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {generatePayslipReport, sendPayslipToReceipt} from '../../../services/payslip'
import useObjectURL from '../../../hooks/useObjectURL'
import {getLastHistoryByHashAndAction} from '../../../services/processRelation'
import {fullName} from '../../../helpers/string-format'
import FullLoader from '../Loaders/FullLoader'
import {payrollDateRoute} from '../../../config/routes'
import PendingProcessModal from '../Modals/PendingProcessModal'
import SelectButton from '../../Molecules/Selects/SelectButton'
import PayslipReportModal from '../Modals/PayslipReportModal'
import PayslipSendModal from '../Modals/PayslipSendModal'
import {selectors as employerSelector} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {selectors as accountingInterfaceConfigSelectors} from '../../../redux/ducks/accountingInterfaceConfigurations'
import HistoryReportModal from '../Modals/HistoryReportModal'
import {stringFormatDate} from '../../../helpers/dates'
import IconBackground from '../../Atoms/IconBackground'
import AccountingInterfaceModal from '../Modals/AccountingInterfaceModal'
import {getAccountingInterfacesByHashProcess} from '../../../services/accountingInterface'
import {generateEmployerReport} from '../../../services/employerReports'

const CONFIRM_CALCULATE = 'CONFIRM_CALCULATE'
const CONFIRM_CLOSE = 'CONFIRM_CLOSE'

const defaultCalculateContentModal = (
  <>
    ¿Estas seguro de calcular este proceso? Esta acción calculará <span className="s-color-title">todos</span> los
    contratos.
  </>
)

const REPORT_SPREADSHEET = 'SPREADSHEET'
const REPORT_CONCEPT_SUMMARY = 'CONCEPT_SUMMARY'
const REPORT_HISTORY = 'HISTORY'
const REPORT_PAYSLIP = 'PAYSLIP'
const REPORT_ACCOUNTING_INTERFACE = 'ACCOUNTING_INTERFACE'
const REPORT_PRIMA = 'PRIMA'
const REPORT_CONSOLIDATED = 'CONSOLIDATED'
const REPORT_RTF_RECALCULATION = 'RTF_RECALCULATION'

const BUTTON_SEND_PAYSLIP = 'SEND_PAYSLIP'
const BUTTON_UPDATE_RTF_PERCENTAGES = 'UPDATE_RTF_PERCENTAGES'

const ProcessHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const toM = useMedia(mediaToM)
  const {activeProcess, setActiveProcess, payrollDate} = useContext(PayrollContext)
  const employer = useSelector(employerSelector.getSession)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const accountingInterfaceConfig = useSelector(accountingInterfaceConfigSelectors.getAll)
  const accountingInterfaceConfigObj = useSelector(accountingInterfaceConfigSelectors.getObj)

  const [accountingInterfaces, setAccountingInterfaces] = useState([])

  const configProcess = configObj[activeProcess?.process_type_code]
  const configReports = configProcess?.config?.reports?.in_process[employer.code_iso_3]
  const employerReports = employer?.params?.reports?.in_process[activeProcess?.process_type_code]

  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isFailureLastHistory, setIsFailureLastHistory] = useState(false)
  const [isLoadingClose, setIsLoadingClose] = useState(false)
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [isLoadingSendPayslip, setIsLoadingSendPayslip] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')

  const confirmModal = useRef()
  const pendingProcessExecModal = useRef()
  const payslipReportModal = useRef()
  const accountingInterfaceModal = useRef()
  const historyReportModal = useRef()
  const payslipSendModal = useRef()

  useEffect(() => {
    if (isEqual(activeProcess.status, STATUS_PRE_PAYROLL)) return
    if (isEmpty(accountingInterfaceConfig)) return

    getAccountingInterfacesByHashProcess(activeProcess.hash)
      .then(response => {
        if (isNull(response)) {
          setAccountingInterfaces([])
          return
        }

        setAccountingInterfaces(response)
      })
      .catch(error => dispatch(notifyError(error)))
  }, [accountingInterfaceConfig, activeProcess.hash, activeProcess.status])

  const {setObject, objectURL} = useObjectURL(null)
  const downloadLinkReport = useRef()

  const handleOpenPendingProcessModal = () => {
    pendingProcessExecModal.current.openModal()
  }

  const handleOpenPayslipReportModal = () => {
    payslipReportModal.current.openModal()
  }

  const handleOpenAccountingInterfaceModal = () => {
    accountingInterfaceModal.current.openModal()
  }

  const handleOpenHistoryReportModal = () => {
    historyReportModal.current.openModal()
  }

  const handleOpenPayslipSendModal = () => {
    payslipSendModal.current.openModal()
  }

  const handleGoToReloadPage = () => {
    history.replace({
      hash: '#reload',
    })
  }

  const handleGoToPaysheetPage = () => {
    history.replace({
      hash: findProcessStage(STAGE_PAYSHEET)?.hash,
    })
  }

  const handleGoToLastPage = () => {
    history.replace({
      hash: findProcessStage(
        Array.isArray(configProcess?.config?.rules?.stages)
          ? configProcess?.config?.rules?.stages[configProcess?.config?.rules?.stages.length - 1]
          : STAGE_PAYSHEET
      )?.hash,
    })
  }

  const handleOnClickCalculate = isReExecute => {
    confirmModal.current.closeModal()
    setIsCalculating(true)

    // Hack 😅. We always move the user to an non-existent page for force update the #hash-tab.
    // so when the user is moved to paysheet page we can update those these.
    handleGoToReloadPage()

    const payload = {
      process_type: activeProcess?.process_type_code,
      action: ACTION_RUN,
      hash: activeProcess?.hash,
      is_re_execute: isReExecute,
    }

    execProcess(
      payload,
      response => {
        switch (response?.exec_status) {
          case EXEC_STATUS_PENDING:
            setActiveProcess({...activeProcess, stage: STAGE_PAYSHEET, updated_at: new Date().toISOString()})

            setIsCalculating(false)
            handleOpenPendingProcessModal()
            break

          case EXEC_STATUS_COMPLETED:
            setActiveProcess({...activeProcess, stage: STAGE_PAYSHEET, updated_at: new Date().toISOString()})

            setIsCalculating(false)
            handleGoToPaysheetPage()
            break

          default:
            break
        }
      },
      error => {
        dispatch(notifyError(error))
        setIsCalculating(false)
      }
    )
  }

  const handleOnClickPayrollSpreadsheet = () => {
    setIsLoadingDownloadReport(true)

    getByEmployerIDAndHash(
      activeProcess.hash,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleOnClickSummaryConceptReport = () => {
    setIsLoadingDownloadReport(true)

    getConceptSummaryByEmployerIDAndHash(
      activeProcess.hash,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleOnClickPrimaReport = () => {
    setIsLoadingDownloadReport(true)

    getPrimaReportByEmployerIDAndHash(
      activeProcess.hash,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleOnClickGeneratePayslipReport = formData => {
    setIsLoadingDownloadReport(true)

    generatePayslipReport(
      activeProcess.hash,
      formData,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleOnClickRefreshAccountingInterface = interfaces => {
    if (isNull(interfaces)) {
      setAccountingInterfaces([])
      return
    }

    setAccountingInterfaces(interfaces)
  }

  const handleOnClickGenerateHistoryReport = (from, to, contractIDs) => {
    setIsLoadingDownloadReport(true)

    getHistoryPayrollByRange(
      stringFormatDate(from),
      stringFormatDate(to),
      contractIDs,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleDownloadEmployerReport = codeReport => {
    setIsLoadingDownloadReport(true)

    const payload = {
      report: codeReport,
      process_hash: activeProcess.hash,
    }

    generateEmployerReport(payload)
      .then(({response, headers}) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'] || codeReport)

        downloadLinkReport.current.click()

        setObject(null)
        setFileNameDownloaded('')
      })
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const handleOnClickClose = () => {
    confirmModal.current.closeModal()
    setIsLoadingClose(true)

    const payload = {
      process_type: activeProcess?.process_type_code,
      action: ACTION_APPROVE,
      hash: activeProcess?.hash,
    }

    const stages = configProcess?.config?.rules?.stages

    execProcess(
      payload,
      response => {
        setActiveProcess({
          ...activeProcess,
          stage: Array.isArray(stages) ? stages[stages.length - 1] : STAGE_PAYSHEET,
          status: STATUS_HISTORY,
          is_paid_full: !Array.isArray(stages) ? false : !isEqual(stages[stages.length - 1], STAGE_PAYMENTS),
        })

        setIsLoadingClose(false)
        handleGoToLastPage()
        dispatch(notifySuccessful(`El proceso ${activeProcess?.hash} fue cerrado correctamente.`))
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingClose(false)
      }
    )
  }

  const handleOnSendPayslip = formData => {
    setIsLoadingSendPayslip(true)

    sendPayslipToReceipt(
      activeProcess.hash,
      formData,
      response => {
        dispatch(notifySuccessful('Hemos enviado los comprobantes correctamente'))
        setIsLoadingSendPayslip(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoadingSendPayslip(false)
      }
    )
  }

  const REPORT_OPTIONS = {
    [REPORT_SPREADSHEET]: {
      key: REPORT_SPREADSHEET,
      description: 'Planilla',
      icon: 'excel',
      isVisible: true,
      onClick: handleOnClickPayrollSpreadsheet,
    },
    [REPORT_CONCEPT_SUMMARY]: {
      key: REPORT_CONCEPT_SUMMARY,
      description: 'Resumen por conceptos',
      icon: 'excel',
      isVisible: true,
      onClick: handleOnClickSummaryConceptReport,
    },
    [REPORT_HISTORY]: {
      key: REPORT_HISTORY,
      description: 'Historico',
      icon: 'excel',
      isVisible: true,
      onClick: handleOpenHistoryReportModal,
    },
    [REPORT_PAYSLIP]: {
      key: REPORT_PAYSLIP,
      description: 'Comprobantes',
      icon: 'document',
      isVisible: true,
      onClick: handleOpenPayslipReportModal,
    },
    [REPORT_ACCOUNTING_INTERFACE]: {
      key: REPORT_ACCOUNTING_INTERFACE,
      description: 'Interfaz Contable',
      icon: 'document',
      isVisible: isEqual(activeProcess?.status, STATUS_HISTORY) && !isEmpty(accountingInterfaceConfig),
      onClick: handleOpenAccountingInterfaceModal,
    },
    [REPORT_PRIMA]: {
      key: REPORT_PRIMA,
      description: 'Prima de servicios',
      icon: 'excel',
      isVisible: true,
      onClick: handleOnClickPrimaReport,
    },
    [REPORT_CONSOLIDATED]: {
      key: REPORT_CONSOLIDATED,
      description: 'Consolidados',
      icon: 'excel',
      isVisible: true,
      onClick: () => {
        console.log('not implemented')
      },
    },
    [REPORT_RTF_RECALCULATION]: {
      key: REPORT_RTF_RECALCULATION,
      description: 'Recalculo Retefuente',
      icon: 'excel',
      isVisible: true,
      onClick: () => {
        console.log('not implemented')
      },
    },
  }

  const CONFIRMATIONS = {
    [CONFIRM_CALCULATE]: {
      title: 'Calcular proceso',
      content: defaultCalculateContentModal,
      hasCodeConfirmation: false,
      fn: handleOnClickCalculate,
    },
    [CONFIRM_CLOSE]: {
      title: 'Cerrar proceso',
      content: '¿Estás seguro de cerrar el proceso?, recuerda que esta acción no se puede revetir.',
      hasCodeConfirmation: true,
      fn: handleOnClickClose,
    },
  }

  const [confirmContent, setConfirmContent] = useState(null)

  const handleOpenConfirmModal = confirmType => {
    setConfirmContent(CONFIRMATIONS[confirmType])

    confirmModal.current.openModal()
  }

  const handleOpenConfirmModalCalculate = () => {
    setIsLoadingModal(true)

    // Set default confirmation content of the modal
    setConfirmContent(CONFIRMATIONS[CONFIRM_CALCULATE])

    getLastHistoryByHashAndAction(activeProcess?.hash, ACTION_RUN, resp => {
      if (isEqual(resp?.status, STATUS_FAILURE) && !isNull(resp?.contract_ids)) {
        // update the content of the confirmation modal
        setConfirmContent({
          ...CONFIRMATIONS[CONFIRM_CALCULATE],
          content: getContentForContracts(resp),
        })
      }

      setIsFailureLastHistory(isEqual(resp?.status, STATUS_FAILURE))

      confirmModal.current.openModal()
      setIsLoadingModal(false)
    })
  }

  return (
    <PayrollHeaderWrapper>
      <div className="s-cross-center s-flex-gap-8px m-flex-gap-16px overflow-ellipsis nowrap">
        <Icon
          className="cursor-pointer"
          svg="arrow-uturn-left"
          size={toM ? '16' : '24'}
          onClick={() => history.push(payrollDateRoute(payrollDate))}
        />
        <div className="overflow-ellipsis">
          <h1 className="t2 s-mb-1 overflow-ellipsis">{activeProcess?.description}</h1>

          <div className="flex s-flex-gap-8px m-flex-gap-12px">
            <IconBackground title="Proceso de nómina" svg="bolt" bgColor="s-bg-alt-red" color="white" />
            <span className="small code-font s-color-light-text">{activeProcess?.hash}</span>
            <ProcessStatusIcon status={activeProcess.status} />
            {activeProcess.is_migrated && <Tag text="Proceso Migrado" color="red" hasCircle></Tag>}
          </div>
        </div>
      </div>
      <div className="s-cross-center s-flex-gap-8px m-flex-gap-12px">
        <>
          {/* Calculate Button */}
          {isEqual(activeProcess?.status, STATUS_PRE_PAYROLL) && (
            <button
              type="button"
              className={`button ${toM && 'small'}`}
              onClick={handleOpenConfirmModalCalculate}
              // we disabled this button when the modal is loading, because the modal can has
              // a delay when do the fetch for query the last process history
              disabled={isCalculating || isLoadingModal}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="bolt" className="s-mr-4px" />
                <span>{isCalculating ? 'Calculando...' : 'Calcula'}</span>
              </div>
            </button>
          )}

          {/* There are not payroll reports if the process still has not been calculated  */}
          {!isEqual(activeProcess?.stage, STAGE_NOVELTIES) && (
            <SelectButton
              isDisable={isLoadingDownloadReport}
              classButton={`ghost ${toM && 'small'}`}
              iconName="document"
              description={isLoadingDownloadReport ? 'Descargando...' : 'Reportes'}
              options={[
                ...getReportsOpts(configReports, REPORT_OPTIONS),
                ...getEmployerReports(employerReports, employer?.params?.reports?.config, handleDownloadEmployerReport),
              ]}
            />
          )}

          {isEqual(activeProcess?.status, STATUS_HISTORY) &&
            Array.isArray(configProcess?.config?.buttons?.after_closing) && (
              <>
                {configProcess?.config?.buttons?.after_closing.map(buttonKey => {
                  if (isEqual(buttonKey, BUTTON_SEND_PAYSLIP)) {
                    return (
                      <button
                        key={BUTTON_SEND_PAYSLIP}
                        className={`button ghost ${toM && 'small'}`}
                        type="button"
                        onClick={handleOpenPayslipSendModal}
                        disabled={isLoadingSendPayslip}
                      >
                        <div className="s-cross-center s-main-center">
                          <Icon svg="send" className="s-mr-4px" />
                          <span>{isLoadingSendPayslip ? 'Enviando...' : 'Envia'}</span>
                        </div>
                      </button>
                    )
                  }

                  if (isEqual(buttonKey, BUTTON_UPDATE_RTF_PERCENTAGES)) {
                    // TODO implement
                    return null
                  }
                })}
              </>
            )}

          {/* Solo se puede aprobar si el stage es PAYSHEET, no usamos el estado porque podría 
          estar en prenomina con stage NOVELTIES, lo que indicaría que nunca se calculo nada */}
          {isEqual(activeProcess?.stage, STAGE_PAYSHEET) && isEqual(activeProcess?.status, STATUS_PRE_PAYROLL) && (
            <button
              type="button"
              className={`button ghost ${toM && 'small'}`}
              onClick={() => handleOpenConfirmModal(CONFIRM_CLOSE)}
              disabled={isLoadingClose}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="lock-open" className="s-mr-4px" />
                <span>{isLoadingClose ? 'Cerrando...' : 'Cierra'}</span>
              </div>
            </button>
          )}
        </>
      </div>

      <ConfirmModal
        ref={confirmModal}
        hasCodeConfirmation={confirmContent?.hasCodeConfirmation}
        // we need the state isFailureLastHistory for the calculate function, so we always
        // send this state to all functions of the functions of the CONFIRMATION object
        confirmFunction={() => confirmContent?.fn(isFailureLastHistory)}
        title={confirmContent?.title}
        content={confirmContent?.content}
      />

      {/* Pending Modal */}
      <PendingProcessModal ref={pendingProcessExecModal} fnButton={handleGoToPaysheetPage} />

      {/* Payslip Report modal */}
      <PayslipReportModal
        ref={payslipReportModal}
        onGenerate={handleOnClickGeneratePayslipReport}
        processType={activeProcess?.process_type_code}
      />

      {/* Accounting interface modal */}
      <AccountingInterfaceModal
        ref={accountingInterfaceModal}
        onFinish={handleOnClickRefreshAccountingInterface}
        process={activeProcess}
        accountingInterfaceConfig={accountingInterfaceConfig}
        accountingInterfaceConfigObj={accountingInterfaceConfigObj}
        accountingInterfaces={accountingInterfaces}
      />

      {/* History Report modal */}
      <HistoryReportModal ref={historyReportModal} onGenerate={handleOnClickGenerateHistoryReport} />

      {/* Send Payslip modal */}
      <PayslipSendModal ref={payslipSendModal} onSend={handleOnSendPayslip} />

      {isCalculating && <FullLoader />}

      {/* This link is hidden, we only use for download the payroll report */}
      <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
        file downloaded
      </a>
    </PayrollHeaderWrapper>
  )
}

const getContentForContracts = processHistory => {
  const size = len(processHistory?.contract_ids)

  return (
    <>
      La última vez que se calculó el proceso se presentaron errores, cuando calculabamos a{' '}
      <span className="s-color-blue">{processHistory?.employees.map(e => fullName(e)).join(', ')}</span>
      {size > 2 && (
        <>
          {' '}
          y <span className="s-color-blue">{`${size - 2} empleado(s) más`}</span>
        </>
      )}
      , esta acción calculará <span className="s-color-title">solo</span>{' '}
      {isEqual(size, 1) ? 'este contrato' : 'estos contratos'}. ¿Quieres continuar?
    </>
  )
}

const getReportsOpts = (configReports, reportOpts) => {
  let reports = []
  if (!Array.isArray(configReports)) return []

  configReports.forEach(c => {
    let optReport = reportOpts[c]

    if (isUndefined(optReport)) return
    reports.push(optReport)
  })

  return reports
}

function getEmployerReports(employerReports, config, fn) {
  let reports = []
  if (!Array.isArray(employerReports) || isUndefined(config)) return []

  employerReports.forEach(code => {
    let optReport = config[code]

    if (isUndefined(optReport)) return
    reports.push({
      key: code,
      description: optReport?.name || code,
      icon: optReport?.icon || 'document',
      isVisible: true, // currently we don't validate constraints to in_process reports
      onClick: () => fn(code),
    })
  })

  return reports
}

export default ProcessHeader
