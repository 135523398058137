import React from 'react'
import ActionTextButton from '../Templates/ActionTextButton'

const DeleteTextButton = ({onClick}) => {
  return (
    <ActionTextButton
      className="s-color-red red"
      onClick={onClick}
      icon="trash"
      text="Eliminar"
    />
  )
}

export default DeleteTextButton
